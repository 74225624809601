import {AutocompleteQuery} from '@common/components/autocomplete/AutocompleteQuery';
import {AddressFormatUtils} from '@common/utils/AddressFormatUtils';
import {Adresa, Komodita, OdberneMistoElektrina, Query} from '@eon.cz/apollo13-graphql-web';
import {SMLOUVY_ODBERNYCH_MIST_QUERY} from '@lib/graphql/queries';
import {isEqual} from 'lodash';
import {HTMLAttributes} from 'react';
import {Controller, FieldValues, Path, useFormContext} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';

type Props = {
    readonly name: Path<FieldValues>;
    readonly defaultValue?: any;
    readonly adresaObchodnihoPartnera: Adresa | null;
    readonly disabled?: boolean;
    readonly vybranaAdresa: boolean;
};

export const OdberneMistoElektrinaAutocomplete = (props: Props) => {
    const {adresaObchodnihoPartnera, name, defaultValue, disabled, vybranaAdresa} = props;
    const {control} = useFormContext();

    const itemToString = (item: OdberneMistoElektrina | undefined): string => {
        if (typeof item === 'object' && 'eanSpotrebni' in item) {
            return item?.eanSpotrebni ?? 'Obchodní partner';
        }

        return '';
    };
    const inputToVars = {
        napetovaUroven: vybranaAdresa ? ['VN', 'VVN'] : ['NN', 'VN', 'VVN'],
        komodita: Komodita.ELEKTRINA,
    };
    const isOptionEqualToValue = (option: OdberneMistoElektrina | undefined, value: OdberneMistoElektrina | undefined): boolean =>
        isEqual(option?.adresa, value?.adresa);
    const renderOption = (props: HTMLAttributes<HTMLLIElement>, misto: OdberneMistoElektrina | undefined) => {
        return (
            <li {...props}>
                <div>
                    <div>
                        <strong>{misto?.eanSpotrebni ?? <FormattedMessage id="kontakt.obchodni.partner" />}</strong>
                    </div>
                    <div>
                        {`${AddressFormatUtils.formatStreetLine(misto?.adresa)}, ${misto?.adresa?.psc} ${misto?.adresa?.mesto} ${misto?.adresa?.mistniCast}`}
                    </div>
                </div>
            </li>
        );
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field: {value, onChange}, fieldState: {error}}) => (
                <AutocompleteQuery<Query, OdberneMistoElektrina>
                    {...props}
                    onChange={onChange}
                    value={value}
                    error={error}
                    query={SMLOUVY_ODBERNYCH_MIST_QUERY}
                    label="kontakty.odbernemisto.elektrina"
                    itemToString={itemToString}
                    menuItem={renderOption}
                    dataToItems={dataToItems(vybranaAdresa ? adresaObchodnihoPartnera : null)}
                    disabled={disabled}
                    inputToVars={inputToVars}
                    isOptionEqualToValue={isOptionEqualToValue}
                />
            )}
        />
    );
};

const dataToItems = (adresaObchodnihoPartnera: Adresa | null) => (data?: Query) => {
    const queryData = data?.smlouvyOdbernychMist?.naseptavac?.edges;

    if (!queryData) {
        return [];
    }

    if (adresaObchodnihoPartnera) {
        const elektrinaField = {
            eanSpotrebni: '',
            adresa: adresaObchodnihoPartnera,
        } as OdberneMistoElektrina;

        const obchodniPartner = elektrinaField;
        // Pridame adresu obchodniho partnera, kdyz mame

        return queryData
            .map((edge) => edge?.node?.elektrina)
            .filter((v) => !!v)
            .concat(obchodniPartner);
    } else {
        return queryData?.map((edge) => edge?.node?.elektrina).filter((v) => !!v);
    }
};
