import {gql} from '@apollo/client';

export const ZISKAT_KONTAKTY_QUERY = gql`
    query ZiskatKontakty {
        kontakty {
            ziskatKontakty {
                id
                kategorie {
                    id
                    klic
                    kod
                    nazev
                    skupina {
                        id
                    }
                }
                druhKomunikace
                kontaktniUdaj
                komodita
                ean
                zvalidovany
                identifikaceReferencnihoObjektu
                typReferencnihoObjektu
                eanAdresa {
                    ulice
                    cisloPopisne
                    cisloOrientacni
                    mesto
                    mistniCast
                    psc
                }
                adresa {
                    ulice
                    cisloPopisne
                    cisloOrientacni
                    mesto
                    mistniCast
                    psc
                }
            }
            skupinyNotifikaci {
                id
                nazev
                klic
                kategorie {
                    id
                    nazev
                    povoleneDruhyKomunikace
                    elektrina
                    kod
                    povolitVyberEAN
                }
            }
        }
        elektrina {
            odbernaMista {
                smlouvyOdbernychMist(filter: {napetoveUrovne: [VN, VVN], stav: AKTIVNI}, first: 1) {
                    edges {
                        node {
                            id
                            elektrina {
                                napetovaUroven
                            }
                        }
                    }
                }
            }
        }
    }
`;
