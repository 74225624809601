import {ApiDate, ObchodniPartnerTyp} from '@eon.cz/apollo13-graphql-web';
import {format, isAfter, isBefore, isFuture, isValid, startOfDay, subDays, subYears} from 'date-fns';
import {FormattedMessage} from 'react-intl';
import * as yup from 'yup';

// Common setup validations
yup.setLocale({
    mixed: {
        required: () => <FormattedMessage id="yup.required" />,
    },
    string: {
        max: ({max}) => <FormattedMessage id="yup.max" values={{znak: max, pismeno: max === 1 ? '' : max > 1 && max < 5 ? 'y' : 'ů'}} />,
        min: ({min}) => <FormattedMessage id="yup.min" values={{znak: min, pismeno: min === 1 ? '' : min > 1 && min < 5 ? 'y' : 'ů'}} />,
        email: () => <FormattedMessage id="yup.email.format" />,
    },
});
const string = yup.string();
const boolean = yup.boolean();
const onlyNumber = string.nullable().matches(/^[0-9]*$/, () => <FormattedMessage id="yup.number" />);
const getRequiredMesssage = (field: string) => <FormattedMessage id="yup.required" values={{field}} />;

// validace na datum
const validateDatum = (value: ApiDate, validFuture?: boolean, validPast?: boolean) => {
    const isValidDatum = isValid(new Date(value));
    const isFutureDatum = isFuture(new Date(value));
    const isPastDatum = isBefore(new Date(value), subDays(new Date(), 1));
    const isTo110yearsOld = isAfter(new Date(value), startOfDay(subYears(new Date(), 110)));
    const isMoreThen18yearsOld = isBefore(new Date(value), subYears(new Date(), 18));
    const oldest = format(startOfDay(subYears(new Date(), 110)), 'dd.MM.yyyy');
    const youngest = format(subYears(new Date(), 18), 'dd.MM.yyyy');

    if (!isValidDatum) {
        return {message: <FormattedMessage id="yup.datum.invalid" />};
    }
    if (isFutureDatum && validFuture) {
        return {message: <FormattedMessage id="yup.datum.budoucnost" />};
    }
    if ((!isTo110yearsOld || !isMoreThen18yearsOld) && validFuture) {
        return {message: <FormattedMessage id="yup.datum.rozmezi" values={{oldest, youngest}} />};
    }
    if (isPastDatum && validPast) {
        return {message: <FormattedMessage id="yup.datum.minulost" />};
    }
    return undefined;
};

const datumNotFuture = string
    .test(
        '',
        ({value}) => validateDatum(value, true)?.message,
        (value) => {
            if (value) {
                return !validateDatum(value, true);
            }
            return true;
        },
    )
    .nullable()
    .typeError('Pole je povinné');

export const CISLO_PRISTROJE_MATCHER_LITERAL = '^\\d{1,18}$';

export const CISLO_PRISTROJE_MATCHER = new RegExp(CISLO_PRISTROJE_MATCHER_LITERAL);

export const isCisloPristroje = (cislo: string) => {
    return cislo && cislo.match(CISLO_PRISTROJE_MATCHER);
};

const typSubjektu = string.oneOf<ObchodniPartnerTyp>([ObchodniPartnerTyp.FYZICKA_OSOBA, ObchodniPartnerTyp.PRAVNICKA_OSOBA]);
const datumNarozeni = datumNotFuture;

// GENVAL 053
const ic = string
    .required(() => getRequiredMesssage('IČO'))
    .length(8, () => <FormattedMessage id="yup.ico" />)
    .matches(/^\d{8}$/, () => <FormattedMessage id="yup.ico" />);

// GENVAL 002
const ean = string.required(() => getRequiredMesssage('EAN')).matches(/^859182400(1|2)\d{8}$/, () => <FormattedMessage id="yup.ean" />);

export const validationSchemaOveritUzivatele = yup.object().shape({
    typSubjektu,

    datumNarozeni: string.when('typSubjektu', {
        is: ObchodniPartnerTyp.FYZICKA_OSOBA,
        then: () => datumNarozeni.required(),
        otherwise: () => string.nullable(),
    }),
    ic: string.when('typSubjektu', {
        is: ObchodniPartnerTyp.PRAVNICKA_OSOBA,
        then: () => ic,
        otherwise: () => string.nullable(),
    }),

    ean: ean.required(),
    cisloElektromeru: string.when('ean', {
        is: (val: string) => /^859182400(1|2)\d{8}$/.test(val),
        then: () => onlyNumber.min(1).max(18).required(),
        otherwise: (schema) =>
            schema.test(
                '',
                () => <FormattedMessage id="yup.required" />,
                (_, ctx) => {
                    if (ctx.parent) {
                        return !/^859182400(1|2)\d{8}$/.test(ctx.parent.ean);
                    }

                    return true;
                },
            ),
    }),
    cestneProhlaseni: boolean.oneOf([true], 'Pole je povinné').required(),
});
