import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';

import {FormattedMessage} from 'react-intl';
import {FormInput} from '../../../common/components/formInput/FormInput';

export const CreateKontaktLTR = () => {
    return (
        <Grid container spacing={1}>
            <Grid size={{xs: 12}}>
                <Typography sx={{fontWeight: 'bold'}}>
                    <FormattedMessage id="kontakty.ltr.varovani" />
                </Typography>
            </Grid>
            <Grid size={{xs: 12}}>
                <FormInput name="adresa.ulice" label="kontakty.ulice" type="text" />
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <FormInput name="adresa.cisloPopisne" label="kontakty.cislo.popisne" type="text" />
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <FormInput name="adresa.cisloOrientacni" label="kontakty.cislo.orientacni" type="text" />
            </Grid>
            <Grid size={{xs: 12}}>
                <FormInput name="adresa.mesto" label="kontakty.mesto" type="text" />
            </Grid>
            <Grid size={{xs: 12}}>
                <FormInput name="adresa.mistniCast" label="kontakty.mistni.cast" type="text" />
            </Grid>
            <Grid size={{xs: 12}}>
                <FormInput name="adresa.psc" label="kontakty.psc" type="text" />
            </Grid>
        </Grid>
    );
};
