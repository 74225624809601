import {ObchodniPartner} from '@eon.cz/apollo13-graphql-web';
import {Typography} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const NULL_KEY = 'null';

type Props = {
    eanOrEic: string;
    obchodniPartner?: ObchodniPartner;
};

export const KontaktSideText: React.FC<Props> = ({eanOrEic, obchodniPartner}) => {
    const {nazevSpolecnosti, jmeno, prijmeni} = obchodniPartner ?? {};

    if (eanOrEic !== NULL_KEY) {
        return (
            <Typography variant="body1">
                <FormattedMessage id="ean" />
                <FormattedMessage id="symbols.semicolon" /> {eanOrEic}
            </Typography>
        );
    }

    if (nazevSpolecnosti) {
        return <Typography variant="body1">{nazevSpolecnosti}</Typography>;
    }

    if (jmeno && prijmeni) {
        return <Typography variant="body1">{jmeno + ' ' + prijmeni}</Typography>;
    }

    return null;
};
