import {gql} from '@apollo/client';

export const SMLOUVY_ODBERNYCH_MIST_QUERY = gql`
    query KontaktyOdbernaMista($inputValue: String!, $napetovaUroven: [NapetovaUroven!], $komodita: Komodita) {
        smlouvyOdbernychMist {
            naseptavac(
                first: 5
                filter: {fulltextValue: $inputValue, fulltextFields: [EANEIC, ADRESA], napetoveUrovne: $napetovaUroven, komodita: $komodita, stav: AKTIVNI}
            ) {
                edges {
                    node {
                        id
                        stav
                        elektrina {
                            napetovaUroven
                            eanSpotrebni
                            adresa {
                                ulice
                                cisloPopisne
                                mesto
                                psc
                                cisloOrientacni
                                mistniCast
                            }
                        }
                    }
                }
            }
        }
    }
`;
