import {NotifikaceKategorie} from '@eon.cz/apollo13-graphql-web';
import {AccountGroupAddContactDialogModel, CreateKontaktForm, CreateKontaktFormProps} from '../forms/CreateKontaktForm';

type Props = {
    readonly open: boolean;
    readonly toggleIsCreating: (isCreating: boolean) => void;
    readonly kategoryInWhichKontaktIsBeingCreated: NotifikaceKategorie;
    readonly refetchKontakty: () => void;
    readonly initialValues: Partial<AccountGroupAddContactDialogModel>;
} & CreateKontaktFormProps;

export const CreateKontaktDialog: React.FC<Props> = ({
    open,
    toggleIsCreating,
    kategoryInWhichKontaktIsBeingCreated,
    adresaObchodnihoPartnera,
    showEan,
    refetchKontakty,
    kontaktniUdajeProNaseptavani,
    druhyKomunikace,
    initialValues,
}) => {
    const handleCloseDialog = () => {
        toggleIsCreating(false);
    };

    return (
        <CreateKontaktForm
            open={open}
            onClose={handleCloseDialog}
            toggleIsCreating={toggleIsCreating}
            refetchKontakty={refetchKontakty}
            kontaktniUdajeProNaseptavani={kontaktniUdajeProNaseptavani}
            druhyKomunikace={druhyKomunikace}
            showEan={showEan}
            adresaObchodnihoPartnera={adresaObchodnihoPartnera}
            kategoryInWhichKontaktIsBeingCreated={kategoryInWhichKontaktIsBeingCreated}
            initialValues={initialValues}
        />
    );
};
