import {CheckIcon} from '@common/components/icons/CheckIcon';
import {ElectricityIcon} from '@common/components/icons/ElectricityIcon';
import {AddressFormatUtils} from '@common/utils/AddressFormatUtils';
import {Adresa, Komodita, Kontakt, KontaktKomunikaceDruh} from '@eon.cz/apollo13-graphql-web';
import {AlternateEmail, ExplicitOutlined, HomeOutlined} from '@mui/icons-material';
import {Chip} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {TooltipIcon} from '../../../common/components/formInput/tooltips/TooltipIcon';
import {MailIcon} from '../../../common/components/icons/MailIcon';
import {PhoneIcon} from '../../../common/components/icons/PhoneIcon';

type Props = {
    readonly kontakt: Kontakt;
    readonly onDeleteClicked: (kontakt: Kontakt) => void;
    readonly readOnly?: boolean;
    readonly handleZvalidovat?: (kontakt: Kontakt) => void;
    readonly notValidated?: boolean;
    readonly disabled?: boolean;
    // readonly type: KontaktKomunikaceDruh;
};
const typeToIcon = (type: KontaktKomunikaceDruh) => {
    switch (type) {
        case KontaktKomunikaceDruh.INT:
            return <AlternateEmail color="secondary" />;
        case KontaktKomunikaceDruh.MOB:
            return <PhoneIcon color="secondary" />;
        case KontaktKomunikaceDruh.DAT:
            return <MailIcon color="secondary" />;
        case KontaktKomunikaceDruh.LTR:
            return <HomeOutlined color="secondary" />;
    }
};

export const KontaktChip: React.FC<Props> = ({kontakt, notValidated, handleZvalidovat, onDeleteClicked}) => {
    const {komodita, ean, druhKomunikace} = kontakt;

    const handleValidateKontakt = () => {
        handleZvalidovat?.(kontakt);
    };

    const handleDelete = () => {
        onDeleteClicked(kontakt);
    };

    const isPostovniAdresa = druhKomunikace === 'LTR';

    const druhAvatar = typeToIcon(kontakt.druhKomunikace);
    const avatar = getAvatar({komodita: komodita ?? undefined, ean: ean ? !!ean : undefined, notValidated}, handleValidateKontakt);

    const label = getChipLabel(isPostovniAdresa, {address: kontakt.adresa ?? undefined, kontaktniUdaj: kontakt.kontaktniUdaj});

    return (
        <Chip
            key={kontakt.id}
            variant="outlined"
            sx={{borderColor: 'primary.main', '.MuiChip-avatar': {color: 'primary.main'}, padding: 1, margin: 0}}
            avatar={
                <>
                    {druhAvatar}
                    {avatar}
                </>
            }
            label={label}
            onDelete={handleDelete}
        />
    );
};

const getAvatar = (parameters: {komodita?: Komodita; ean?: boolean; notValidated?: boolean}, handleValidateKontakt: () => void) => {
    if (parameters.notValidated) {
        return (
            <TooltipIcon htmlText={<FormattedMessage id="kontakty.zvalidovat" />} showIcon={false}>
                <CheckIcon
                    sx={{
                        fontSize: '1.3rem',
                        fontWeight: 'bold',
                        color: 'primary.main',
                        cursor: 'pointer',
                        border: '1px solid',
                        borderRadius: '50%',
                        padding: 0.2,
                        '&:hover': {
                            color: 'white',
                            backgroundColor: 'primary.main',
                            borderColor: 'primary.main',
                        },
                        marginLeft: 1,
                    }}
                    onClick={handleValidateKontakt}
                />
            </TooltipIcon>
        );
    }

    if (parameters.komodita === 'ELEKTRINA') {
        return <ElectricityIcon sx={{color: 'secondary.main'}} />;
    }

    if (parameters?.ean) {
        return <ExplicitOutlined />;
    }
};

const getChipLabel = (isPostovniAdresa: boolean, {address, kontaktniUdaj}: {address?: Adresa; kontaktniUdaj: string}) =>
    isPostovniAdresa ? <span>{AddressFormatUtils.formatStreetLine(address)}</span> : <span>{kontaktniUdaj}</span>;
