import {useMediaQuery} from '@mui/material';
import {ReactNode} from 'react';

export const isNullOrUndefined = <T extends Record<string, unknown> | string | null | ReactNode>(val: T) => val == null;

export const isNullOrUndefinedOrEmpty = <T extends Record<string, unknown> | string | null | ReactNode>(val: T) =>
    isNullOrUndefined(val) || val === '' || val === 'undefined';

/**
 * If the value is not null, undefined, or an empty string, return true.
 * @param {T} val - T - The value to check
 */
export const isNotNullOrUndefinedOrEmpty = <T extends string | undefined | null>(val: T) => !isNullOrUndefinedOrEmpty(val);

/**
 * It returns true if the screen is less than 500px wide
 * @param [maxWidth=500px] - The maximum width of the screen to match.
 */
export const useMatches = (maxWidth = '500px') => useMediaQuery(`(max-width:${maxWidth})`);
