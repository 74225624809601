import {gql} from '@apollo/client';

export const GET_MY_UCET_SKUPINA_QUERY = gql`
    query GetMyUcetSkupina {
        ucetSkupina {
            getMyUcetSkupina {
                id
                poskytovatelElektrina
                poskytovatelPlyn
                cislo
                elektrina
                nazev
                ic
                dic
                datovaSchranka
                typ
                obchodniPartner {
                    nazevSpolecnosti
                    ic
                    jmeno
                    prijmeni
                    adresa {
                        cisloOrientacni
                        cisloPopisne
                        mesto
                        mistniCast
                        psc
                        ulice
                    }
                }
                prihlaseniCertifikatem
            }
        }
    }
`;
